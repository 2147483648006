<template>
  <div class="flex">
    <div class="bg-mask"></div>
    <div class="bg-login"></div>
    <div class="m-auto mt-52 w-1/2 z-10 ">
      <img src="../assets/images/logo/logo.png" alt="">
    </div>
    <div class="blur-box">
      <p class="text-center pt-4 pb-10 font-medium text-white">لطفا شماره همراه خود را وارد کنید</p>
      <label class="block">
        <span class="text-white opacity-50">شماره همراه</span>
        <input
            type="number"
            pattern="\d*"
            class="mt-0 block text-white w-full py-4 px-0.5 border-0 border-b-2 opacity-50 focus:opacity-100  focus:ring-0 "
            v-model="phone"
            :class="isError ? 'border-red-500 focus:border-red-500' : 'border-white focus:border-white' "
        >
        <span
        v-if="isError"
        class="text-sm text-red-500 text-right inline-block mt-1"
        >{{error}}</span>
      </label>
      <a class="btn-custom mt-9" @click.prevent="sendCode">درخواست تایید کد</a>
<!--      <p class="text-white text-xs text-center mt-11">حساب کاربری نداری؟<a href="" class="text-yellow-400">عضو شوید</a>
      </p>-->
    </div>
  </div>
</template>

<script>
import axios from "../plugins/axios";
export default {
  name: "UserLogin",
  data(){
    return{
      phone : '',
      error : null,
      isError : false
    }
  },
  methods :{
     sendCode(){
      axios.post('/verification/request',{
        phone : this.phone
      })
      .then( async ({ data }) => {
        if (data.status === true){
         await this.$store.commit('auth/SET_USER_PHONE' , this.phone)
         await this.$router.push({name :'UserVerify'})
        }
      })
      .catch((err)=>{
        this.isError = true;
        this.error = err.message
      })
    }

  },
  computed :{

  }
}
</script>

<style scoped>

</style>